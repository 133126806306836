// Not using the following any more because it was annoying with TypeScript
// Polyfill, from https://developer.mozilla.org/en-US/docs/Web/API/NodeList/forEach
// if (window.NodeList && !NodeList.prototype.forEach) {
//   NodeList.prototype.forEach = Array.prototype.forEach;
// }
// if (window.HTMLCollection && !HTMLCollection.prototype.forEach) {
//   HTMLCollection.prototype.forEach = Array.prototype.forEach;
// }

const isIE11 =
  !!window.MSInputMethodContext && !!(document as any).documentMode;
if (isIE11) {
  alert(
    "Sorry, we don’t support Internet Explorer. Please use a modern browser.",
  );
}

/**
 * from: https://vanillajstoolkit.com/polyfills/stringreplaceall/
 * String.prototype.replaceAll() polyfill
 * https://gomakethings.com/how-to-replace-a-section-of-a-string-with-another-one-with-vanilla-js/
 * @author Chris Ferdinandi
 * @license MIT
 */
if (!String.prototype.replaceAll) {
  String.prototype.replaceAll = function (str, newStr) {
    // If a regex pattern
    if (
      Object.prototype.toString.call(str).toLowerCase() === "[object regexp]"
    ) {
      return this.replace(str, newStr);
    }

    // If a string
    return this.replace(new RegExp(str, "g"), newStr);
  };
}
